import { toast } from 'react-hot-toast';

export const handleError = (e: any) => {
  const resp = e.response;

  if (resp.data?.message) {
    return toast.error(resp.data?.message);
  }

  return toast.error(e.message);
};
