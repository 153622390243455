import React, { useMemo, useRef } from 'react'
import { FormProvider } from '@/hook-form'
import RHInput from '@/hook-form/RHInput'
import RHTextarea from '@/hook-form/RHTextarea'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { saveContact, ContactData } from '@/npc-api/contact'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { FormattedMessage, useIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'
import { useConfig } from '@/hooks/useConfig'
import { getLocal } from '../BtnSwitchLang'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'

const Contact = () => {
  const { captchaSiteKey } = useConfig()
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const intl = useIntl()
  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required('Name is required'),
    phone: Yup.string(),
    email: Yup.string().required('Email is required').email(),
    company: Yup.string(),
    message: Yup.string().required('Message is required'),
  })

  const defaultValues = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      company: '',
      message: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: ContactData) => {
    if (!recaptchaRef.current?.getValue()) {
      toast.error('Please check the Captcha field to validate you are not a robot')
      return
    }

    GTM.sendEvent({
      event: GTMEventType.RequestContact,
    })

    await saveContact(data).then((e) =>
      reset({ name: '', phone: '', email: '', company: '', message: '' }),
    )
    recaptchaRef.current?.reset()
  }
  return (
    <section id="contact" className="atf-section-padding ">
      <div className="container container-contact-custom">
        <div className="row">
          <div className="col-lg-12">
            <div className="atf-section-title title-colorr text-left">
              <h3 className="text-center">
                <FormattedMessage id="GetTouchT1" defaultMessage={`Get in `} />
                <span>
                  <FormattedMessage id="GetTouchT2" defaultMessage={`Touch`} />
                </span>{' '}
              </h3>
              <p className="f-15 mt-3 text-center">
                <FormattedMessage
                  id="GetTouchSumary"
                  defaultMessage={`Send us a message, and we'll be happy to provide the information you need.`}
                />
              </p>

              <div className="contact mt-4">
                <FormProvider
                  name="fwdedamn"
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                  className="atf-contact-form form"
                >
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <RHInput
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({
                          id: 'GetTPLaceName',
                          defaultMessage: 'Your Name',
                        })}
                        required={true}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <RHInput
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({
                          id: 'GetTPLaceEmail',
                          defaultMessage: 'Your Email',
                        })}
                        required={true}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <RHInput
                        name="phone"
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({
                          id: 'GetTPLacePhone',
                          defaultMessage: 'Your Phone',
                        })}
                        required={false}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <RHInput
                        name="company"
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({
                          id: 'GetTPLaceCompany',
                          defaultMessage: 'Company',
                        })}
                      />
                    </div>

                    <div className="form-group col-lg-12">
                      <RHTextarea
                        className="form-control"
                        name="message"
                        placeholder={intl.formatMessage({
                          id: 'GetTPLaceMessage',
                          defaultMessage: 'Your Message',
                        })}
                      />
                    </div>
                    {captchaSiteKey && (
                      <div className="col-lg-12 mb-3">
                        <ReCAPTCHA
                          sitekey={captchaSiteKey}
                          ref={recaptchaRef}
                          size="normal"
                          hl={getLocal() === 'en' ? 'es' : getLocal()}
                        />
                      </div>
                    )}
                    <div className="col-lg-12">
                      <div className="atf-contact-btn text-left d-flex justify-content-center">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          value="Submit Now"
                          name="submit"
                          id="submitButton"
                          className="atf-themes-btn"
                          title="Submit Your Message!"
                        >
                          {intl.formatMessage({
                            id: 'GetTBTNSend',
                            defaultMessage: 'Send Messagee',
                          })}
                        </button>
                      </div>
                    </div>
                  </div>
                </FormProvider>
                <p className="form-message"></p>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6">
            <div
              className="atf-contact-info ps-lg-5 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              data-wow-offset="0"
            >
              <div className="atf-section-title">
                <h3>
                  {intl.formatMessage({ id: 'ContactInfo', defaultMessage: 'Contact details' })}
                </h3>
                <p className="f-15 mt-3 ">
                  {intl.formatMessage({
                    id: 'ContactInfoSumary',
                    defaultMessage:
                      "We'll answer any questions you have and help you choose the right service for your needs.",
                  })}
                </p>
              </div>

              <div className="atf-contact-details mt-4">
                <i className="fa fa-home"></i>
                <h5>
                  {' '}
                  {intl.formatMessage({
                    id: 'AddressContact',
                    defaultMessage: 'Address Location',
                  })}
                </h5>
                <p>9703 S Dixie Hwy SUITE 114, Pinecrest, FL 33156</p>
              </div>

              <div className="atf-contact-details mt-4">
                <OutboundLink href="tel:+13056145989">
                  <i className="fa fa-phone"></i>
                  <h5>
                    {intl.formatMessage({
                      id: 'ContactPhone',
                      defaultMessage: 'Phone Number',
                    })}
                  </h5>
                  <p>(305) 614-5989</p>
                </OutboundLink>
              </div>

              <div className="atf-contact-details mt-4">
                <i className="fa fa-envelope"></i>
                <h5>
                  {intl.formatMessage({
                    id: 'ContactMail',
                    defaultMessage: 'Mail Address',
                  })}
                </h5>
                <OutboundLink href="mailto:atokindness@gmail.com">
                  <p>atokindness@gmail.com</p>
                </OutboundLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Contact
