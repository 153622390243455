import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Pagination, Autoplay } from 'swiper'
import { useViewport } from '@/hooks/useViewPort'
import { FormattedMessage } from 'react-intl'
import Image from '../Image'

const SwiperTestimonial = () => {
  const { isMobile, isTabletOnly } = useViewport()
  return (
    <Swiper
      slidesPerView={isMobile || isTabletOnly ? 1 : 2}
      spaceBetween={30}
      loop={true}
      pagination={false}
      autoplay={{
        delay: 2500,
      }}
      modules={[Pagination, Autoplay]}
      className="owl-carousell owl-theme atf-main-testimonials"
    >
      <SwiperSlide>
        <div className="testimonial" style={{ position: 'relative' }}>
          <div className="pic">
            {/* <img src="images/client/img-1.jpg" alt="client" /> */}
            <Image name="customer-default.png" classes="img-fluid" />
          </div>
          <p className="description reviewText">
            This agency labels itself as a touch of goodness and it's not just its name, but in
            reality meets all the conditions for the ideal treatment that people deserve that are
            under the care of its staff, which is delicately selected to offer the best service with
            human quality. Excellent management! I classify them as good professionals and the
            treatment for me as HHA has been very good. Thanks for the Considerations to Mrs.
            Janeth, Aurora and Kirenia
            {/* <FormattedMessage
              id="CLRCard1"
              defaultMessage={`" I want to give a special thanks for the care your team provided to my father during
            the last 3 years. You have a very professional and efficient staff. I feel very
            satisfied to see my dad happy and well cared for.."`}
            /> */}
          </p>
          <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, margin: 'auto' }}>
            <h5 className="testimonial-title">Noelia Roche</h5>
            <ul className="testimonial-rating">
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
            </ul>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial" style={{ position: 'relative' }}>
          <div className="pic">
            {/* <img src="images/client/img-2.jpg" alt="client" /> */}
            <Image name="customer-default.png" classes="img-fluid" />
          </div>
          <p className="description reviewText ">
            In all the time that I have been using the HHA service, in this agency I have been
            treated Super good. I have no complaints about your staff, the attention is always
            excellent, for my are the best Much to thank all the ladies who work there...
            {/* <FormattedMessage
              id="CLRCard2"
              defaultMessage={`" My mom has dementia and needs special care. A Touch Of Kindness Home Care Inc. have responsive to my
            mom's needs. They have excellent and highly qualified caregivers. I feel grateful and
            calm for the great work that they do."`} 
            />*/}
          </p>
          <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, margin: 'auto' }}>
            <h5 className="testimonial-title">Carlota Asencio</h5>
            <ul className="testimonial-rating">
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
            </ul>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="testimonial" style={{ position: 'relative' }}>
          <div className="pic">
            <Image name="customer-default.png" classes="img-fluid" />

            {/* <img src="images/client/img-3.jpg" alt="client" /> */}
          </div>
          <p className="description reviewText">
            I am HHA, I have been working for this agency for a while and I can "recommend" it as
            much as worker or as a patient because I have the opinion that here you work with a
            value human that I do not believe exists in another, both the owner and the coordinator
            are people with an ethic, a moral with a sense of responsibility in their work, a human
            value that is not found in many places, these people are able to offer their workers and
            patients differentiated care where they take care of and care about each one of us,
            gentlemen if you are looking for a good agency for your family member or friend here it
            is 100% and if you are looking for a job do not hesitate in the cases that are offered
            here
            {/* <FormattedMessage
              id="CLRCard3"
              defaultMessage={`" I recommend you for your efficiency in caring for the elderly. I think you all do a
            great professional job. I am very grateful for all the support and help you are giving
            me in such a difficult situation of elder care with my father."`}
            /> */}
          </p>
          <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, margin: 'auto' }}>
            <h5 className="testimonial-title">Anabel Romero</h5>
            <ul className="testimonial-rating">
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
            </ul>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial" style={{ position: 'relative' }}>
          <div className="pic">
            <Image name="customer-default.png" classes="img-fluid" />

            {/* <img src="images/client/img-3.jpg" alt="client" /> */}
          </div>
          <p className="description reviewText">
            I am very happy with the care they give me at the touch of kindness agency in Special to
            Yanet and Aurora, they deserve to keep going. Kind regards, Yakelin Miranda.
            {/* <FormattedMessage
              id="CLRCard3"
              defaultMessage={`" I recommend you for your efficiency in caring for the elderly. I think you all do a
            great professional job. I am very grateful for all the support and help you are giving
            me in such a difficult situation of elder care with my father."`}
            /> */}
          </p>
          <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, margin: 'auto' }}>
            <h5 className="testimonial-title">Yakelin Miranda</h5>
            <ul className="testimonial-rating">
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
            </ul>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial" style={{ position: 'relative' }}>
          <div className="pic">
            <Image name="customer-default.png" classes="img-fluid" />

            {/* <img src="images/client/img-3.jpg" alt="client" /> */}
          </div>
          <p className="description reviewText">
            Great services, I recommend A touch home care, very good service, I highly recommend
            them good agency.
            {/* <FormattedMessage
              id="CLRCard3"
              defaultMessage={`" I recommend you for your efficiency in caring for the elderly. I think you all do a
            great professional job. I am very grateful for all the support and help you are giving
            me in such a difficult situation of elder care with my father."`}
            /> */}
          </p>
          <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, margin: 'auto' }}>
            <h5 className="testimonial-title">Carlos Gomez</h5>
            <ul className="testimonial-rating">
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
              <li className="fa fa-star"></li>
            </ul>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default SwiperTestimonial
