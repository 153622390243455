import React from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";

const CustomToggle = ({ children, eventKey, setActive, active }: any) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setActive(eventKey);
    if (active === eventKey) {
      setActive(null);
    }
  });

  return (
    <h2 className="accordion-header text-uppercase " id="headingOne">
    <button
      className={`accordion-button faq-title ${active !== eventKey ? "collapsed" : ""}`}
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseOne"
      aria-expanded={active === eventKey ? "true" : "false"}
      aria-controls="collapseOne"
      onClick={(e) => decoratedOnClick(e)}
    >
        {children}
    </button>
  </h2>
  );
};

type CardProp = {
  eventKey: string;
  active: string;
  setActive: any;
  title: string;
  body: string;
};
const Item = ({ eventKey, active, setActive, title, body }: CardProp) => {
  return (
    <div className="accordion-item">
      <CustomToggle eventKey={eventKey} setActive={setActive} active={active}>
        {title}
      </CustomToggle>

      <Accordion.Collapse
        eventKey={eventKey}
        id="acc-show"
        className={`accordion-collapse collapse ${active === eventKey ? "show" : ""}`}
        data-parent="#faqAccordions2"
      >
        <div className="accordion-body f-15 faq-desc">
          {body}
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default Item;
