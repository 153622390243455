import React from 'react'
import { FormattedMessage } from 'react-intl'
import Image from '../Image'

const Services = () => {
  return (
    <section id="service" className="atf-section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div
              className="atf-section-title title-color Activities text-center wow zoomIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <h3>
                {' '}
                <FormattedMessage id="STitlep1" defaultMessage="Our " />
                <span>
                  <FormattedMessage id="STitlep2" defaultMessage="Services" />
                </span>{' '}
              </h3>
              <div className="title-divider mt-3">
              <Image name='atok_logo_circular.png' classes='img-fluid' style={{display:'block',margin:'auto',width:'50px'}}/>

                {/* <img src="images/atok_logo_circular.png" className="img-fluid" alt="divider" /> */}
              </div>
              <p className="f-15 mt-3">
                <FormattedMessage
                  id="ServiceSumary"
                  defaultMessage="Being a caregiver for a family member can be rewarding and requires a lot of time
                and energy. Let us help you keep the comfort and well-being of your loved ones and
                provide peace of mind for you and your family."
                />
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="card-group atf-services mt-5">
            <div className="card custom-card-service">
            <div className='card-custom-content'>
              <h1>A Touch of Kindness Home Care Inc.</h1>
              <img alt='img' src='/images/divider-service-card.svg'/>
              <p className="f-15 mt-3">
                <FormattedMessage
                  id="ServiceSumary2"
                  defaultMessage="Is proud to have committed and well-trained caregivers who work every day to improve
                  the quality of life of your loved ones."
                />
              </p>
              <p className="f-15 mt-3">
                <FormattedMessage
                  id="ServiceSumary3"
                  defaultMessage="Let us help you with your Long Term Care Services (LTC). If you do not have Long Term Care, please let us know, we can help you in that process."
                />
              </p>
            </div>
            <img id='logbg' alt='logo' src='/images/logocard.png'/>
            </div>

            <div className="card atf-facilities">
              <div className="atf-facilities-img">
                <div className="atf-overlay"></div>
                {/* <img
                  className="card-img-top"
                  src="images/services/personal-care.png"
                  alt="Personal care"
                /> */}
                <Image name='personal-care.png' classes='card-img-top'/>

                <h5 className='card-title-custom'>
                  <FormattedMessage id="SCard1Title" defaultMessage="Personal Care" />
                </h5>
              </div>

              <div className="card-body">
                <p className='custom-card-description'>
                  {' '}
                  <FormattedMessage
                    id="SCard1TitleSumary"
                    defaultMessage="Maintains a person's dignity, health, and well-being. We assist with daily
                  activities, such as personal hygiene care, nutrition, or medication reminder."
                  />
                </p>
              </div>
            </div>

            <div className="card atf-facilities">
              <div className="atf-facilities-img">
                <div className="atf-overlay"></div>
                <Image name='companion.png' classes='card-img-top'/>
                {/* <img className="card-img-top" src="images/services/companion.png" alt="Companion" /> */}
                <h5 className='card-title-custom'>
                  <FormattedMessage id="SCard2Title" defaultMessage="Private Duty Care Companion" />
                </h5>
              </div>

              <div className="card-body">
                <p className='custom-card-description'>
                  {' '}
                  <FormattedMessage
                    id="SCard2TitleSumary"
                    defaultMessage="Caregivers assist with daily activities and provide healthy living and well-being
                  through the benefits of social interaction."
                  />
                </p>
              </div>
            </div>

            <div className="card atf-facilities">
              <div className="atf-facilities-img">
                <div className="atf-overlay"></div>
                <Image name='respite-care.png' classes='card-img-top'/>

                {/* <img
                  className="card-img-top"
                  src="images/services/respite-care.png"
                  alt="Respite care"
                /> */}
                <h5 className='card-title-custom'>
                  <FormattedMessage id="SCard3Title" defaultMessage="Respite" />
                </h5>
              </div>

              <div className="card-body">
                <p className='custom-card-description'>
                  {' '}
                  <FormattedMessage
                    id="SCard3TitleSumary"
                    defaultMessage="Provide care for the health and well-being of your loved one as long as you need."
                  />
                </p>
              </div>
            </div>

            <div className="card atf-facilities">
              <div className="atf-facilities-img">
                <div className="atf-overlay"></div>
                <Image name='escort.png' classes='card-img-top'/>

                {/* <img className="card-img-top" src="images/services/escort.png" alt="Escort" /> */}
                <h5 className='card-title-custom'>
                  <FormattedMessage id="SCard4Title" defaultMessage="Escort" />
                </h5>
              </div>

              <div className="card-body">
                <p className='custom-card-description'>
                  {' '}
                  <FormattedMessage
                    id="SCard4TitleSumary"
                    defaultMessage="Provide assistance and companionship with doctors appointments, grocery, bank, and
                  errands."
                  />
                </p>
              </div>
            </div>

            <div className="card atf-facilities">
              <div className="atf-facilities-img">
                <div className="atf-overlay"></div>
                <Image name='homemaker.png' classes='card-img-top'/>

                {/* <img className="card-img-top" src="images/services/homemaker.png" alt="Homemaker" /> */}
                <h5 className='card-title-custom'>
                  <FormattedMessage id="SCard5Title" defaultMessage="Homemaker" />
                </h5>
              </div>

              <div className="card-body">
                <p className='custom-card-description'>
                  {' '}
                  <FormattedMessage
                    id="SCard5TitleSumary"
                    defaultMessage="Our staff will keep a safe, clean, and comfortable living environment for your
                  loved one."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
