import React from 'react'
import { Ps } from '@/components/Home/PartnersSlider'
import { FormattedMessage } from 'react-intl'

const Partners = () => {
  return (
    <section className="bg-light atf-testimonial-area atf-section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div
              className="atf-section-title title-color text-center wow zoomIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <h3>
                <FormattedMessage id="OurP" defaultMessage={`Our `} />
                <span>
                  <FormattedMessage id="OurP2" defaultMessage={`Partners`} />
                </span>{' '}
              </h3>
              {/* <hr className='sub-sc'/> */}
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-4">
          <div className="col-lg-12 clearfix">
            <Ps />
          </div>
          <div className='wrapper-p'>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '4px', height: '4px', background: '#72DEC9' }} />
              <span
                style={{
                  width: '400px',
                  height: '0px',
                  background: '#72DEC9',
                  marginLeft: '8px',
                  border: '2px solid #72DEC9',
                }}
              />
            </div>
            <div className='sponsorinfoctr'>
              <div>
                <img src='/images/partnerimg.svg' alt=''/>
              </div>
              <div><p className='txpinf'>A proud member of the Home Care Association of Florida</p></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners
