import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Pagination, Autoplay } from 'swiper'
import Image from '../Image'
const SwiperAbout = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      // pagination={{
      //   clickable: true,
      // }}
      pagination={false}
      autoplay={{
        delay: 2500,
      }}
      modules={[Pagination, Autoplay]}
      className="atf-about-active atf-main-testimonials owl-carousell owl-theme"
    >
      <SwiperSlide>
        <div className="atf-about-image">
          <div className="atf-about-images text-center">
            
            <Image name="33ab.png" classes="img-fluid" />
            {/* <img src="images/about/1.jpg" className="img-fluid" alt="about" /> */}
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="atf-about-image">
          {/* <div className="atf-singl-videonone"> */}
            <div className="atf-about-images text-center">
              <Image name="2-ab.png" classes="img-fluid" />

              {/* <img src="images/about/2.jpg" className="img-fluid" alt="about" /> */}
            </div>
            {/* <div className="waves-box">
              <a
                href="https://www.youtube.com/embed/NPfnLKLuJ_U"
                data-rel="lightcase:myCollection"
                className="iq-video"
              >
                {' '}
                <i className="fa fa-play"></i>
              </a>
              <div className="iq-waves">
                <div className="waves wave-1"></div>
                <div className="waves wave-2"></div>
                <div className="waves wave-3"></div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="atf-about-image">
          <div className="atf-about-images text-center">
            <Image name="22ab.png" classes="img-fluid" />

            {/* <img src="images/about/3.jpg" className="img-fluid" alt="about" /> */}
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default SwiperAbout
