export const data = [
  {
    id: 'FaqQt1',
    title: "What are the benefits of in-home care?",
    body: "Home care services provide family support and improve quality of life while allowing your loved one to stay in the comfort of their home and surrounded by their family and friends."
  },
  {
    id: 'FaqQt2',
    title: "How much do in-home care services cost me?",
    body: "Costs vary depending on the service requested and the number of hours required. We have different choices according to the budget of each family. You must contact our care manager to discuss a personalized plan of care for you.",
  },
  {
    id: 'FaqQt3',
    title: "​What do home care services include?",
    body: "We provide services according to the individual needs of each client. Caregivers are experts at caring for people with functional diversity. They know all the techniques to facilitate the patient's daily activities for living and improve their quality of life. Besides, they will be able to perform the rest of the tasks included in the service, such as personal care or light housekeeping.",
  },
];
