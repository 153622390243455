import { GTMEventType } from '@/types/google-tag-manager.interface'
import { GTM } from '@/utils/google-tag-manager'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionHome = () => {
  return (
    <section
      id="home"
      className="atf-header-area atf-align-items-details position-relative"
      style={{
        backgroundImage: 'url(/images/nurse-hero.jfif)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      //   style="background-image: url(assets/img/bg/bg.jpg);  background-size:cover; background-position: center center;"
    >
      <div className="hero-over-2"></div>
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-xl-7 col-lg-6 col-12 atf-single-detailss text-left">
            <h5 className="mb-4 text-s1-t">
              <span className="rounded-background">
                <FormattedMessage
                  id="heroTitl1"
                  defaultMessage={`Lean on us! We are exactly what you need.`}
                />
              </span>
            </h5>
            <h2 className="text-uppercase text-s-t">
              <FormattedMessage id="heroTitle2" defaultMessage={`We are here to support you.`} />
            </h2>
            <p className="f-17 mt-4 text-s-3">
              <FormattedMessage
                id="heroSubtitle"
                defaultMessage={`Let us help you keep the comfort and well-being of your loved ones and provide peace
              of mind for you and your family.`}
              />
            </p>

            {/* <div className="atf-main-btn atf-home-btn mt-5"> */}
            <div className="atf-main-btn mt-5 bt-sect ">
              <a
                href="#contact"
                className="atf-themes-btn-custom btn-hero atf-main-btn-custom"
                onClick={() => {
                  GTM.sendEvent({
                    event: GTMEventType.InitContactRequest,
                  })
                }}
              >
                <FormattedMessage id="admissionNow" defaultMessage={`admissionNow`} />
              </a>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="hero-overlay"></div>
    </section>
  )
}

export default SectionHome
