import React, { useMemo } from 'react'
import { FormProvider } from '@/hook-form'
import RHInput from '@/hook-form/RHInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { saveSubscriber, SubscriberData } from '@/npc-api/subscriber'
import { FormattedMessage, useIntl } from 'react-intl'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'

const Subscrition = () => {
  const ContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email(),
  })
  const intl = useIntl()

  const defaultValues = useMemo(
    () => ({
      email: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const methods = useForm<SubscriberData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: SubscriberData) => {
    GTM.sendEvent({
      event: GTMEventType.RequestSubscription,
    })

    await saveSubscriber(data).then((e) => reset({ email: '' }))
  }
  return (
    <section
      style={{ marginTop: '30rem' }}
      className="atf-newsletterr atf-section-padding bg-sub sbm"
    >
      <div className="container">
        <div className="row clearfix">
          <div
            className="col-lg-6 text-center wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
            data-wow-offset="0"
          >
            <div
              className="atf-mailchamp-headding"
              style={{ textAlign: 'left', marginLeft: '5rem' }}
            >
              <h2 style={{ color: 'white' }}>
                <FormattedMessage id="SubscribeT" defaultMessage={`Subscribe Now`} />
              </h2>
              <span style={{ color: 'white' }}>
                <FormattedMessage id="SubscribeT2" defaultMessage={`For care information`} />
              </span>
            </div>
          </div>
          <div
            className="col-lg-6 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
            data-wow-offset="0"
          >
            <div className="atf-mailchamp-subscribe">
              <FormProvider
                name="ftmn"
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
                className="form-group"
              >
                <RHInput
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: 'SubscribeTPLaceHolder',
                    defaultMessage: 'Enter your email',
                  })}
                  required={true}
                />

                <button
                  disabled={isSubmitting}
                  type="submit"
                  id="subscribe-button"
                  className="btn"
                  style={{ color: 'white' }}
                >
                  <i className="far fa-envelope" style={{ marginRight: '11.67px' }}></i>{' '}
                  {isSubmitting
                    ? '...'
                    : intl.formatMessage({
                        id: 'SubscribeBtn',
                        defaultMessage: 'Subscribe',
                      })}
                </button>

                <br />
                <label className="atf-subscription-label" htmlFor="email"></label>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Subscrition
