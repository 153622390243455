// form
import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

// ----------------------------------------------------------------------

interface IProps {
  name: string
  type?: string
  placeholder?: string
  defaultValue?: string
  className?: string
  required?: boolean
  row?: number
}

export default function RHTextarea({ name, ...other }: IProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <textarea {...field} {...other} />
          {error && <p style={{ color: 'red' }}>{error.message}</p>}
        </>
      )}
    />
  )
}
