import React from 'react'
import { FormattedMessage } from 'react-intl'
import Image from '../Image'

const SectionFeature = () => {
  return (
    <section id="feature" className="atf-feature-area bg-light atf-section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div
              className="atf-section-title title-color text-center wow zoomIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <h3>
                <FormattedMessage id="welcomeTitlePart1" defaultMessage="Welcome to " />
                <span>A Touch Of Kindness Home Care Inc.</span>
              </h3>
              <div className="title-divider mt-3">
                {' '}
              <Image name='atok_logo_circular.png' classes='img-fluid' style={{display:'block',margin:'auto',width:'50px'}}/>

                {/* <img src="images/atok_logo_circular.png" className="img-fluid" alt="divider" /> */}
              </div>
              <p className="f-15 mt-3">
                <FormattedMessage
                  id="welcomeSumary"
                  defaultMessage="The best solution for supporting all your needs by experienced staff providing home
                care services that are reliable and high quality. Life will be a little easier for
                you and your loved one."
                />
              </p>
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3 pt-4 atf-sec-cards">
          <div className="col">
            <div
              className="atf-single-feature box-shadow-active-not text-center wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
              data-wow-offset="0"
            >
              <div className="atf-feature-icon">
              {/* <Image name='high-quality.svg' classes='img-fluid' /> */}

                <img src="/images/HighQualityIcon.svg" alt="Feature" />
              </div>
              <div className="atf-feature-text">
                <h5 className="mt-4">
                  <FormattedMessage id="welcomeCard1Title" defaultMessage="High quality services" />
                </h5>
                <p className="mt-3">
                  <FormattedMessage
                    id="welcomeCard1Subtitle"
                    defaultMessage=" Personalized quality services according to the individual needs of clients."
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div
              className="atf-single-feature text-center wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              data-wow-offset="0"
            >
              <div className="atf-feature-icon">
                <img src="/images/SupportIcon.svg" alt="Feature" />
              </div>
              <div className="atf-feature-text">
                <h5 className="mt-4">
                  <FormattedMessage id="welcomeCard2Title" defaultMessage="Support that you need" />
                </h5>
                <p className="mt-3">
                  <FormattedMessage
                    id="welcomeCard2Subtitle"
                    defaultMessage="We listen to your needs and provide the right solution for you and your loved
                  ones."
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div
              className="atf-single-feature text-center wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
              data-wow-offset="0"
            >
              <div className="atf-feature-icon">
                <img src="/images/Frame2.svg" alt="Feature" />
              </div>
              <div className="atf-feature-text">
                <h5 className="mt-4">
                  <FormattedMessage
                    id="welcomeCard3Title"
                    defaultMessage="Well trained caregivers"
                  />
                </h5>
                <p className="mt-3">
                  <FormattedMessage
                    id="welcomeCard3Subtitle"
                    defaultMessage="Trained and experienced caregivers to provide the best home care services."
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div
              className="atf-single-feature text-center wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              data-wow-offset="0"
            >
              <div className="atf-feature-icon">
                <img src="/images/AffordablePriceIcon.svg" alt="Feature" />
              </div>
              <div className="atf-feature-text">
                <h5 className="mt-4">
                  <FormattedMessage id="welcomeCard4Title" defaultMessage="Affordable prices" />
                </h5>
                <p className="mt-3">
                  <FormattedMessage
                    id="welcomeCard4Subtitle"
                    defaultMessage="We provide a custom care plan specific to the client's needs and budget."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFeature
