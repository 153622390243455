import React from 'react'
import { FormattedMessage } from 'react-intl'
import SwiperAbout from './SwiperAbout'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'

const SectionAbout = () => {
  return (
    <section id="about" className="atf-section-padding text-white-custom">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="atf-section-title text-left wow fadeIn "
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <h3 style={{ color: '#FFFF' }}>
                <FormattedMessage id="aboutUsTitle" defaultMessage="About Us" />
              </h3>
              <p className="f-15 mt-3" style={{ color: '#FFFF' }}>
                <span>A Touch Of Kindness Home Care Inc.</span>
                <FormattedMessage
                  id="aboutUsSumary"
                  defaultMessage=" is committed to providing the highest quality, compassionate care for you and your loved ones with honesty, dignity and respect. Through our customer-focused and service-oriented approach, respecting the diversity of each individual. We want to make sure your loved ones succeed and thrive in a safe environment with A Touch of Kindness. Some of our fundamental values ​​that constitute the basis of our services are:"
                />
              </p>

              <div className="row ">
                <div className="col-6">
                  <div className="atf-about-iconc">
                    <h5 className="d-flex flex-row align-items-center text-card-about text-uppercase">
                      {/* <i className="fas fa-check pe-1"></i> */}
                      <img
                        style={{ marginRight: '16px', height: '41px', width: '41px' }}
                        className="pe-1"
                        src="/images/Frame 521.svg"
                      />
                      <FormattedMessage id="Kindness" defaultMessage="Kindness" />
                    </h5>
                  </div>
                </div>
                <div className="col-6">
                  <div className="atf-about-iconc">
                    <h5 className="d-flex flex-row align-items-center text-card-about text-uppercase">
                      {/* <i className="fas fa-check pe-1"></i> */}
                      <img
                        style={{ marginRight: '16px', height: '41px', width: '41px' }}
                        className="pe-1"
                        src="/images/Frame 521.svg"
                      />

                      <FormattedMessage id="Integrity" defaultMessage="Integrity" />
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="atf-about-iconc">
                    <h5 className="d-flex flex-row align-items-center text-card-about text-uppercase">
                      {/* <i className="fas fa-check pe-1"></i> */}
                      <img
                        style={{ marginRight: '16px', height: '41px', width: '41px' }}
                        className="pe-1"
                        src="/images/Frame 521.svg"
                      />

                      <FormattedMessage id="Confidence" defaultMessage="Confidence" />
                    </h5>
                  </div>
                </div>
                <div className="col-6">
                  <div className="atf-about-iconc">
                    <h5 className="d-flex flex-row align-items-center text-card-about text-uppercase">
                      {/* <i className="fas fa-check pe-1"></i> */}
                      <img
                        style={{ marginRight: '16px', height: '41px', width: '41px' }}
                        className="pe-1"
                        src="/images/Frame 521.svg"
                      />

                      <FormattedMessage id="Custom-care" defaultMessage="Custom care" />
                    </h5>
                  </div>
                </div>
              </div>

              <div className="atf-main-btn atf-about-btn mt-4">
                <a
                  href="#contact"
                  className="atf-themes-btn-custom-about"
                  onClick={() => {
                    GTM.sendEvent({
                      event: GTMEventType.InitContactRequest,
                    })
                  }}
                >
                  <FormattedMessage id="RequestInfoBtn" defaultMessage="Request info" />

                  {/* <i className="fa fa-angle-right"></i> */}
                  {/* <img  className="pe-1" src="/images/Frame 521.svg" /> */}
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <SwiperAbout />
          </div>
        </div>
      </div>
      <div className="overlayAbout"></div>
    </section>
  )
}

export default SectionAbout
