import React from 'react'
import Contact from './Contact'

const Map = () => {
  return (
    <div id="map" className="atf-map-area atf-section-padding map-container">
      <div className="containerx">
        <div className="row">
          <div className="col-lg-12">
            <div id="atf-map-area">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.727847691119!2d-80.31744188545925!3d25.68031488367378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9c7aed6109e09%3A0xf45b27a379415217!2s9703%20S%20Dixie%20Hwy%20%23114%2C%20Pinecrest%2C%20FL%2033156!5e0!3m2!1ses-419!2sus!4v1655135210348!5m2!1ses-419!2sus"
                style={{ border: 0,height:'446px' }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className='contact-container'>

      <Contact/>
      </div>
    </div>
  )
}

export default Map
