import React from 'react'
import SectionHome from '@/components/Home/SectionHome'
import SectionFeature from '@/components/Home/SectionFeature'
import SectionAbout from '@/components/Home/SectionAbout'
import Counter from '@/components/Home/Counter'
import Services from '@/components/Home/Services'
import Testimonial from './Testimonial'
import Team from '@/components/Home/Team'
import Subscrition from '@/components/Home/Subscrition'
import Contact from '@/components/Home/Contact'
import Faq from '@/components/Home/Faq'
import Map from '@/components/Home/Map'
import Licensed  from '@/components/Home/Licensed '
import Partners from '@/components/Home/Partners'

const Home = () => {
  return (
    <>
      <SectionHome />
      <SectionFeature />
      <SectionAbout />
      {/* <Counter /> */}
      <Services />
      <Testimonial />
      {/* <Team /> */}
      <Licensed />
      <Partners/>
      <Faq />
      <Map />
      {/* <Contact /> */}
      <Subscrition />
    </>
  )
}

export default Home
