import React from 'react'
import { FormattedMessage } from 'react-intl'

const Licensed = () => {
  return (
    <div className="row justify-content-center ">
      <div className="col-lg-12">
        <div className="atf-section-title title-color-l lic lic-container">
          <div className="container content-lic">
            <div>
              <h3 style={{ color: '#FBFBFB' }}>
                <FormattedMessage id="LicP1" defaultMessage={`Licensed by (AHCA) `} />
                <br />
                <span>
                  <FormattedMessage
                    id="LicP2"
                    defaultMessage={`Agency for Health Care Administration`}
                  />
                </span>
                <FormattedMessage id="LicP3" defaultMessage={` and`} />{' '}
                <span>
                  <FormattedMessage id="LicP4" defaultMessage={`Medicaid`} />
                </span>
              </h3>
            </div>
            <div>
              <div style={{ display: 'flex' }}>
                <span style={{ width: '4px', height: '4px', background: '#72DEC9' }} />
                <span
                  style={{
                    width: '300px',
                    height: '0px',
                    background: '#72DEC9',
                    marginLeft: '8px',
                    border: '2px solid #72DEC9',
                  }}
                />
              </div>
              {/* <img alt='img' src='/images/div-lic.svg' style={{zIndex:5}}/> */}
              <p className="f-15 sub-ltext" style={{ marginTop: '12px' }}>
                <FormattedMessage
                  id="LicSumary"
                  defaultMessage={`We are an experienced team, totally dedicated and committed to our patients.
              Experienced in the care of patients with Alzheimers and Dementia.`}
                />
              </p>
              <p className="f-15 sub-ltext" style={{ marginTop: '12px' }}>
                <FormattedMessage
                  id="LicSumaryCaregivers"
                  defaultMessage={`Our caregivers are thoroughly screened and all undergo a Level II background check through AHCA (Agency for Healthcare Administration).`}
                />
              </p>
              <p className="f-15 sub-ltext" style={{ marginTop: '12px' }}>
                Lic. #30212075
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Licensed
