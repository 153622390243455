import { Config } from '@/types'

const config: Config = {
  siteTitle: "A Touch of Kindness",
  siteFavicon: '/favicon.ico',
  logo: '/images/atok_logo_rectangular.png',
  navbarFixedOnScroll: true,
  captchaSiteKey: '6Lekm6UiAAAAAEfGNB3xTVwhjlkUsMa5CYyZ2-Rt',
}

export default config
export type { Config }
