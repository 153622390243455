import React from 'react'
import { Accordion } from 'react-bootstrap'
import Item from './Faq/Item'
import { data } from './Faq/data'
import { FormattedMessage, useIntl } from 'react-intl'

const Faq = () => {
  const [active, setActive] = React.useState('0')
  const intl = useIntl()

  return (
    <section id="faq" className="atf-faq-area bg-lighte atf-section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="atf-section-title title-color atf-choose-area text-center">
              <h3>
                <FormattedMessage id="FaqT1" defaultMessage={`Frequently `} />
                <span>
                  <FormattedMessage id="FaqT2" defaultMessage={`Asked`} />
                </span>
                <FormattedMessage id="FaqT3" defaultMessage={` Question`} />
              </h3>
              <p className="f-15 mt-3">
                <FormattedMessage
                  id="FaqSumary"
                  defaultMessage={`If you have any other questions, feel free to contact us. We'd be glad to get you
                the information you need so you can make the right decision for you and your loved
                ones.`}
                />
              </p>
              <Accordion defaultActiveKey="0" className="accordion mt-5 text-left pt-4">
                {data.map((item, index) => {
                  if (item.id === 'FaqQt1') {
                    return (
                      <Item
                        key={index}
                        eventKey={item.id}
                        active={active}
                        setActive={setActive}
                        title={intl.formatMessage({
                          id: 'FaqQt1',
                          defaultMessage: item.title,
                        })}
                        body={intl.formatMessage({
                          id: 'FaqQt1Sumary',
                          defaultMessage: item.body,
                        })}
                      />
                    )
                  }
                  if (item.id === 'FaqQt2') {
                    return (
                      <Item
                        key={index}
                        eventKey={item.id}
                        active={active}
                        setActive={setActive}
                        title={intl.formatMessage({
                          id: 'FaqQt2',
                          defaultMessage: item.title,
                        })}
                        body={intl.formatMessage({
                          id: 'FaqQt2Sumary',
                          defaultMessage: item.body,
                        })}
                      />
                    )
                  }
                  if (item.id === 'FaqQt3') {
                    return (
                      <Item
                        key={index}
                        eventKey={item.id}
                        active={active}
                        setActive={setActive}
                        title={intl.formatMessage({
                          id: 'FaqQt3',
                          defaultMessage: item.title,
                        })}
                        body={intl.formatMessage({
                          id: 'FaqQt3Sumary',
                          defaultMessage: item.body,
                        })}
                      />
                    )
                  }
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq
