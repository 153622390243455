import React from 'react'
import { FormattedMessage } from 'react-intl'
import Image from '../Image'
import SwiperTestimonial from './SwiperTestimonial'

const Testimonial = () => {
  return (
    <section id="testimonial" className="bg-light atf-testimonial-area atf-section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div
              className="atf-section-title title-color text-center wow zoomIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <h3>
                <FormattedMessage id="CLReviewT1" defaultMessage="Client " />
                <span>
                  <FormattedMessage id="CLReviewT2" defaultMessage="Review" />
                </span>{' '}
              </h3>
              <div className="title-divider mt-3">
                {' '}
                <Image name='atok_logo_circular.png' classes='img-fluid' style={{display:'block',margin:'auto',width:'50px'}}/>

                {/* <img src="images/atok_logo_circular.png" className="img-fluid" alt="divider" /> */}
              </div>
              <p className="f-15 mt-3 ">
                <FormattedMessage
                  id="CLRSumary"
                  defaultMessage="We thank our customers for beautiful comments that encourage us to continue striving
                to provide the best services."
                />
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-4">
          <div className="col-lg-12 clearfix">
            <SwiperTestimonial />
            {/* <div
              id="atf-testimonial-slider"
              className="owl-carousel owl-theme atf-main-testimonials"
            >
              <div className="testimonial">
                <div className="pic">
                  <img src="images/client/img-1.jpg" alt="client" />
                </div>
                <p className="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi
                  facilis itaque minus non odio, quaerat ullam under"
                </p>
                <h5 className="testimonial-title">Abdul Hamid</h5>
                <span className="post">Senior Teacher</span>
                <ul className="testimonial-rating">
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                </ul>
              </div>

              <div className="testimonial">
                <div className="pic">
                  <img src="images/client/img-2.jpg" alt="client" />
                </div>
                <p className="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi
                  facilis itaque minus non odio, quaerat ullam under"
                </p>
                <h5 className="testimonial-title">Abdul Jabber</h5>
                <span className="post">Senior Teacher</span>
                <ul className="testimonial-rating">
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                </ul>
              </div>

              <div className="testimonial">
                <div className="pic">
                  <img src="images/client/img-3.jpg" alt="client" />
                </div>
                <p className="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi
                  facilis itaque minus non odio, quaerat ullam under"
                </p>
                <h5 className="testimonial-title">Abdul Hamid</h5>
                <span className="post">Senior Teacher</span>
                <ul className="testimonial-rating">
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                </ul>
              </div>

              <div className="testimonial">
                <div className="pic">
                  <img src="images/client/img-2.jpg" alt="client" />
                </div>
                <p className="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi eligendi
                  facilis itaque minus non odio, quaerat ullam under"
                </p>
                <h5 className="testimonial-title">Abdul Hakim</h5>
                <span className="post">Senior Teacher</span>
                <ul className="testimonial-rating">
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                  <li className="fa fa-star"></li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
