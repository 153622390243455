import React, { FC } from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'

const partnerData = [
  {
    imageSrc: '/images/p1-1.png',
    url: 'https://fcchealthplan.com/',
    styles: {
      width: '100%',
      height: '100%',
    },
  },
  {
    imageSrc: '/images/p2-2.png',
    url: 'https://ihcscorp.com/',
    styles: {
      width: '75%',
      height: '75%',
    },
  },
  {
    imageSrc: '/images/p3-3.png',
    url: 'https://www-es.sunshinehealth.com/',
    styles: {
      width: '95%',
      height: '95%',
    },
  },
  {
    imageSrc: '/images/humana-logo.svg',
    url: 'https://www.humana.com/',
    styles: {
      width: '95%',
      height: '95%',
    },
  },
  {
    imageSrc: '/images/p5-5.png',
    url: 'https://www.simplyhealthcareplans.com/florida-home/simply.html',
    styles: {
      width: '95%',
      height: '95%',
    },
  },
  {
    imageSrc: '/images/p6-6.png',
    url: 'https://www.lhanc.org/',
    styles: {
      width: '95%',
      height: '95%',
    },
  },
  {
    imageSrc: '/images/p7-7.png',
    url: 'https://www.molinahealthcare.com/',
    styles: {
      width: '95%',
      height: '95%',
    },
  },
  {
    imageSrc: '/images/p-88.svg',
    url: 'https://es.aetna.com',
    styles: {
      width: '95%',
      height: '95%',
    },
  },
]

const swiperBreakpoints = {
  595: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 50,
  },
}

const Partner: FC<PartnerProps> = ({ imageSrc, url, styles }) => (
  <>
    <div className="client-logo-display-single-item swiper-slide d-flex flex-row justify-content-center">
      <div className="image">
        <a href={url} target="_blank">
          <img className="img-fluid" src={imageSrc} alt="Partner" style={styles} />
        </a>
      </div>
    </div>
  </>
)

export const Ps: FC = () => (
  <Swiper
    spaceBetween={30}
    loop={true}
    className="owl-carousell owl-theme atf-main-testimonials"
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    breakpoints={swiperBreakpoints}
    modules={[Autoplay]}
  >
    {partnerData.map((data, idx) => (
      <SwiperSlide key={`slide-${idx}`}>
        <Partner imageSrc={data.imageSrc} url={data.url} styles={data.styles} />
      </SwiperSlide>
    ))}
  </Swiper>
)

type PartnerProps = {
  imageSrc: string
  url: string
  styles: React.CSSProperties
}
